// 我的授信
<template>
 <div class="item">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>我的授信</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="CreditBox">
      <p v-if="creditShow" class="Mymsg">{{MyMSG}}</p>
      <TitleHeader msg="我的授信"></TitleHeader>
      <el-row class="numWraper">
        <span class="CreditInstructions" @click="visible = !visible">
        <!-- 授信使用说明 > -->
        <strong class="el-icon-question"></strong> 授信使用说明 >
        </span>

        <el-col :span="6">
          <p class="balaceTit">当前余额（元）</p>
          <span class="balaceNum redColor">{{ num }}</span>
           <div class="btn"></div>
          <!-- <router-link
              :to="{ name: 'CreditRecharge' }"
              tag="div"
              class="btn CreditRechargeBtn"
              >回款</router-link
            > -->
        </el-col>
        <el-col :span="6">
          <p class="balaceTit">已用额度（元）</p>
          <span class="balaceNum">{{ usedLimited }}</span>
          <div class="btn"></div>
        </el-col>
        <el-col :span="6">
          <p class="balaceTit">授信额度（元）</p>
          <span class="balaceNum">{{ creditLine }}</span>
          <div class="btn"></div>
        </el-col>
        <el-col :span="6">
          <p class="balaceTit">授信账期（月）</p>
          <span class="balaceNum">{{ month }}</span>
          <div class="btn"></div>
        </el-col>
        <!-- <el-col :span="4">
          <el-col
            ><router-link
              :to="{ name: 'Recharge' }"
              tag="div"
              class="btn RechargeBtn"
              >充值</router-link
            ></el-col
          >
          <el-col
            ><router-link
              :to="{ name: 'TransferAccount' }"
              tag="div"
              class="btn TransferAccountBtn"
              >转账</router-link
            ></el-col
          >
        </el-col> -->
      </el-row>
      <p class="progressBar"></p>
      <el-row class="fromWraper">
        <el-row>
          <Nav :obj="obj" @clickIndex="changePropsIndex"></Nav>
        </el-row>
          <span v-if="isListFlag">当前待还金额 : <strong>{{toPaidBalance}}</strong></span>
          <span v-if="!isListFlag">当前结余金额 : <strong>{{balance}}</strong></span>
      </el-row>
      <!-- 结算单明细   START-->
      <el-row v-show="isListFlag">
        <ul class="ulBox">
           <li>序号</li>
          <li>结算单号</li>
          <li class="uldiv">结算金额(元)</li>
          <li class="uldiv">已还金额(元)</li>
          <li class="uldiv">待还金额(元)</li>
          <li>创建日期</li>
          <li>应还款日期</li>
          <li>结清日期</li>
          <li class="uldiv">结算状态</li>
          <li>逾期天数</li>
          <li>操作</li>
        </ul>        <!--  label="交易时间"          label="交易金额"-->
        <el-table  
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="tableData"
          :show-header="false"
          class="wraper"
          style="width: 100%;"
        >
        <el-table-column   type="index"  label="序号"  width="56" align="center"></el-table-column>
          <el-table-column class="text-center" prop="id"  width="150" align="center" label="结算单号" >
          </el-table-column>
          <el-table-column
            class="text-center"
            prop="orderAmount"
            width="100"
            label="结算金额(元)"
            align="center" 
          >
          </el-table-column>

          <el-table-column
            class="text-center"
            prop="alreadyRepayAmount"
            label="已还金额(元)"
            width="100"
            align="center" 
          >
          </el-table-column>
          <el-table-column
            class="text-center"
            prop="renamingAmount"
            label="待还金额(元)"
            width="100"
            align="center" 
          >
          </el-table-column>
          <el-table-column class="text-center" label="创建日期" prop="createTime"  width="114" align="center" >
          </el-table-column>

           <el-table-column class="text-center" label="还款日" prop="repayDate"  width="114" align="center" >
          </el-table-column>
           <el-table-column class="text-center" label="结算日" prop="settlementDate"  width="114" align="center" >
          </el-table-column>
           <el-table-column class="text-center" label="结算状态"   width="100" align="center" >
              <template slot-scope="scope">
                  <span v-if="scope.row.status==0">未结清</span>
                  <span  v-else> 已结清</span>
              </template>
          </el-table-column>
           <el-table-column class="text-center" label="逾期天数" prop="overdueDays"  width="114" align="center" >
          </el-table-column>
           <el-table-column class="text-center" label="首营资料">
            <template slot-scope="scope">
              <el-button  v-if="scope.row.status==0"  type="text" size="small"  class="repayBtn" @click.native="ToPayFun(scope.row.renamingAmount,scope.row.repayDate,scope.row.id)">去还款</el-button>
               <el-button :class="scope.row.status==0?'':'noRepayBtn' " type="text" size="small"  @click.native="detailListFun(scope.row.id)">查看明细</el-button>
            </template>
          </el-table-column>
        </el-table>
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="false"
              prev-text="上一页"
              next-text="下一页"
              :page-size="PageSize"
              :current-page="current"
              :total="total"
               @current-change="handleCurrentChange"
              >
          </el-pagination>
      </el-row>
      <!-- 结算单明细   END-->
      <!-- 回款明细 START-->
      <el-row v-show="!isListFlag">
        <ul class="ulBoxs">
          <li>序号</li>
          <li>回款单号</li>
          <li>回款金额(元)</li>
          <li>回款时间</li>
          <li>审核状态</li>
        </ul>
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="tableDatas"
          :show-header="false"
          style="width: 100%;"
        >
          <el-table-column class="text-center" type="index"  width="180" align="center" label="序号" >
          </el-table-column>
          <el-table-column
            class="text-center"
            prop="id"
            width="250"
            label="回款单号"
            align="center" 
          >
          </el-table-column>
          <el-table-column
            class="text-center"
            prop="repayAmount"
            label="回款金额(元)"
            width="250"
            align="center" 
          >
          </el-table-column>
          <el-table-column
            class="text-center"
            prop="createTime"
            label="回款时间"
            width="250"
            align="center" 
          >
          </el-table-column>
           <el-table-column class="text-center" label="审核状态"   width="250" align="center" >
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0">审核失败</span>
                    <span v-else-if="scope.row.status==1">正常 </span>
                    <span v-else> 审核中</span>
                </template>
            </el-table-column>
        </el-table>
      </el-row>
      <!-- 回款明细 END-->
    </div>
    <ListDiolag  :detailListData="detailListData"  ></ListDiolag>

    <!-- 还款  start -->
    <el-dialog
      id="PayBox"
      title="结算单回款"
      :visible.sync="dialogPayBoxVisible"
      width="600px"
      height="200px"
    >



<el-row class="content">
      <el-col class="text-left charge">
        <span>待还金额：</span><span class="num">{{RepaymentAmount   | capitalize }}</span>
      </el-col>
      <el-col class="wraper">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <el-form-item label="回款金额" prop="num">
            <el-input v-model="ruleForm.num" clearable  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"></el-input> 元
          </el-form-item>
          <p>注：还款日为{{RepaymentDate}}，请及时回款</p>
          <el-divider></el-divider>
          <el-form-item prop="resource" label="支付方式">
            <el-radio-group v-model="ruleForm.resource" size="medium">
              <el-col
                :span="8"
                v-for="(item, index) in imgArr"
                :key="index"
                :class="
                  index === imgArrSelect ? 'imgWraper active' : 'imgWraper'
                "
                @click.native="changeFun(item, index)"
              >
                <i class="el-icon-success" aria-hidden="true"></i
                ><img :src="item.img" :alt="item.alt" />
              </el-col>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
      <div class="clear"></div>
    </el-dialog>
        <!-- 支付二维码弹框 -->
    <el-dialog
      title="扫码充值"
      id="QRCodeBox"
      center
      :visible.sync="DialogShowQRCode"
      width="300px"
      height="300px"
      @close="closeDialog"
    >
      <vue-qr
        :bgSrc="config.logo"
        :logoSrc="config.logo"
        :text="config.value"
        :size="200"
      ></vue-qr>
    </el-dialog>
    <!-- 还款  end -->

    <!-- 授信说明 -->
    <el-dialog
    id="TitMsgBox"
      :visible.sync="visible"
      width="36%"
      >
      <ul id="scrollLoadContent">
        <h4 class="BigTitle">我的授信使用说明</h4>
        <p class="MidTitle">
          <b>1.</b> 会员授信账户的开通，均需经过公司严格的风控措施，在线下流程通过后经由内勤在系统录入，经由财务审核后方可成功开通；
        </p>
        <p class="MidTitle">
          <b>2.</b> 功能开通后，正常状态下，用户可在订单结算时选用授信支付； 
        </p>
        <p class="MidTitle">
          <b>3.</b> 如需做授信额度调整，则需线下沟通后在系统录入，审核通过后再生效； 
        </p>
        <p class="MidTitle">
         <b>4</b> 根据授信账期，系统会自动根据所设定的的结算日自动将待结算订单生成对应的结算单，用户可根据结算单进行还款操作；
        </p>
        <p class="MidTitle">
          <b>5.</b> 用户在还款时，选择结算单后，可通过微信，支付宝或结余金额支付的方式进行还款，支持部分还清；
        </p>
        <p class="MidTitle">
         <b>6.</b> 如需线下还款，打款后联系内勤，我们会将您的款项充值至预存款，充值完成后您可通过预存款主动还款或等到还款日自动扣款；
        </p>
        <p class="MidTitle">
        <b>7.</b> 系统会在账期还款日时，从会员的授信结余金额中，按结算单生成时间由远及近进行自动扣除，足额还清后结算单状态变为已结清；
        </p>
        <p class="MidTitle">
       <b>8.</b> 若超过还款日仍不能足额还清，则会影响到您后期授信额度的使用及调整，请您及时注意及时还款； 
        </p>
        <p class="MidTitle">
        <b>9.</b> 若还款日扣除时不足以还清，则系统会自动在还款日T+2日，进行再次扣除，若此时仍不能足额还清，则该会员授信账户将被自动锁定，直至还清后自动解锁；
        </p>
      </ul>
    </el-dialog>
 </div>
</template>

<script>
import VueQr from "vue-qr";
const ListDiolag = () => import("components/common/ListDiolag.vue");
// creditPay.js
import { 
  getCurrentMemberStoreCreditStatus,//获取当前门店的授信状态
  // getCurrentMemberStoreAvailableQuota,//获取当前门店的可用额度
  getCurrentMemberStoreCreditBaseInfo, //获取当前门店的授信基本信息
  listCurrentMemberStoreSettlementOrder,  //查询结算单列表
  // getSettlementOrderDate,   //获取结算单日期
  getCurrentMemberStoreOfflineRepay,// 回款明细
  initiatePayment,//发起支付
  checkPCPayIsSuccess,//检查是否还款成功

} from "api/creditPay.js";
const TitleHeader = () => import("components/common/TitleHeader.vue");
const Nav = () => import("components/common/Nav.vue");
export default {
  name: "Credit",
  data() {
    return {
      MyMSG:'',
      creditShow:false,
      visible:false,
       DialogShowQRCode: false,
      config: {
        value: "", //显示的值、跳转的地址
        imagePath: require("@/assets/logo.png"), //中间logo的地址
      },
      RepaymentAmount :0,//还款金额
      RepaymentDate:'',//还款日期
       ruleForm: {
        num: "", //要还款的金额
        resource: 0,
      },
      rules: {
        num: [
          { required: true, message: "请输入要还款的金额", trigger: "blur" },
        ],
        resource: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
      },
      imgArr: [
        {
          img:
            "http://image.demo.b2b2c.shopxx.net/6.0/16d9ceaf-ada6-480d-95c3-6244eac4b0ee.png",
          alt: "微信支付",
          num: 0,
        },
        {
          img:
            "http://image.demo.b2b2c.shopxx.net/6.0/aa348738-a710-4330-915d-a2d2475681e6.png",
          alt: "支付宝",
          num: 1,
        },
      ],
      imgArrSelect: 0,
      IdData:'',//支付订单Id


      dialogPayBoxVisible:false,
      isListFlag:true,
      PageSize:10,
       current: 1, //当前页
      total:0,
        size: 10, //每页数量
      //选项卡默认选择第一项
      currentIndex: 0,
      //加载loading条
      loading: false,
     // 当前余额
      num: 0,
      //授信账期
      month: 0,
      //已用额度
      usedLimited: 0,
      //授信额度
      creditLine: 0,
      // 当前结余金额
      balance:0,

      // 当前待还金额
      toPaidBalance:0,

      //导航栏信息
      obj: {
        content: [
          {
            type: 0,
            name: "结算单明细",
          },
          {
            type: 1,
            name: "回款明细",
          },
        ],
        currentIndex: 0,
      },
      tableData: [],
      tableDatas:[],
      detailListData:{},//列表初始弹窗开关
      StatementId:'',//结算单Id
    };
  },
  methods: {
     closeDialog() {
      this.$confirm("请根据您的支付情况点击下面的按钮", "请确认支付是否完成", {
        confirmButtonText: "已完成支付",
        cancelButtonText: "支付遇到问题",
        type: "warning",
      })
        .then(() => {
          
          // 去判断是否还款成功
          checkPCPayIsSuccess(this.IdData).then((res)=>{
            //console.log(res.data.data)
              if(res.data.data){//支付成功
                    // 跳转充值成功页面
                    this.$message({
                      message:'还款成功',
                      type: "success",
                    });

              }else{
                  this.$message({
                    message:'您未还款成功',
                    type: "warning",
                  });
              }
          })
        })
        .catch(() => {
           // 去判断是否还款成功
          checkPCPayIsSuccess(this.IdData).then((res)=>{
            //console.log(res.data.data)
              if(res.data.data){//支付成功
                    // 跳转充值成功页面
                    this.$message({
                      message:'还款成功',
                      type: "success",
                    });

              }else{
                  this.$message({
                    message:'您未还款成功',
                    type: "warning",
                  });
              }
          })
        });
    },
    // 确定还款
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
              if (valid) {
                 if(this.ruleForm.num==0){
                  this.ruleForm.num=0.1
                   this.$message.error("还款金额不能少于一分钱");
                  return false
                }
                if(this.ruleForm.num>this.RepaymentAmount){
                  this.ruleForm.num=this.RepaymentAmount
                   this.$message.error("不能超过最大还款金额");
                  return false
                }
                let datas = {
                  id:this.StatementId,   //结算单id
                  type: 0,   // 0：PC 1：APP
                  repayAmount:this.ruleForm.num,   //还款金额
                  repayType:this.ruleForm.resource, //还款类型 0：微信还款 1：支付宝还款
                };
                initiatePayment(datas).then((res) => {
                  console.log(res)
                    if (res.data.code == 200) {
                      //获取支付链接  生成二维码
                      this.DialogShowQRCode = true;
                      this.config.value = res.data.data.pay.billQRCode;
 this.IdData =res.data.data.repayId
                      this.dialogPayBoxVisible=false
                    }
                });
              } else {
                return false;
              }
      });
    },
   //   选择支付方式
    changeFun(item, index) {
      this.imgArrSelect = index;
      this.ruleForm.resource = item.num;
    },

    // 
    changePropsIndex(params){
      this.obj.currentIndex = params;
      if(params===0){
        this.current = 1;
        this.isListFlag=true,
          this.tableData=[],
         this.GetListFun();
      }else{
        this.isListFlag=false,
        this.current = 1;
        this.tableDatas=[],
        this.GetReturnedMoneyListFun()
      }
    },
    handleCurrentChange(val) {
      this.current = val;
      this.GetListFun();
    },
// 回款明细
 GetReturnedMoneyListFun(){
    let datas={
            current:this.current,
            size:this.PageSize
          }
    getCurrentMemberStoreOfflineRepay(datas).then(res=>{
      console.log('查询回款明细;',res.data)

      console.log('回款明细;',res.data.data)
      this.balance=res.data.data.balance
      this.tableDatas=res.data.data.offlineRepayData
      // this.total=res.data.data.total
    })
 },
    // 结算单列表
    GetListFun(){
         // 查询结算单列表
          let datas={
            current:this.current,
            size:this.PageSize
          }
          listCurrentMemberStoreSettlementOrder(datas).then(res=>{
            console.log('查询结算单列表;',res.data.data)
            // console.log('结算单5456列表;',res.data.data.records)
            
            this.tableData=res.data.data.records
            this.total=res.data.data.total
             this.toPaidBalance=0
            this.tableData.forEach(item=>{
              this.toPaidBalance+=Number(item.renamingAmount)
            })
            this.toPaidBalance=this.toPaidBalance.toFixed(2)
          })
    },

    // 初始门店授权基本数据
    initFun(){
      //  获取当前门店的授信状态   status:{  1:正常  3:冻结      -1 未开通   0:审核失败   2:审核中 }
      getCurrentMemberStoreCreditStatus().then(res=>{
        // alert('45645656',res.data.code)
        if(res.data.data.status===3 ){
           this.MyMSG="(您的授信账户已被冻结，请联系客服)"
           this.creditShow=true
        }else{
           this.creditShow=false
        }
      })
       // 获取当前门店的授信基本信息
      getCurrentMemberStoreCreditBaseInfo().then(res=>{
        this.num=res.data.data.availableQuota    //当前余额
        this.usedLimited=res.data.data.alreadyUsedQuota   //已用额度
        this.creditLine=res.data.data.currentQuota   //授信额度
        this.month=res.data.data.currentAccountPeriod   //授信账期
      })
    },
// 去还款
ToPayFun(RepaymentAmount,RepaymentDate,StatementId){
    this.RepaymentAmount=RepaymentAmount,//还款金额
      this.RepaymentDate=RepaymentDate,//还款日期
this.ruleForm.num=0,
      this.StatementId=StatementId
  this.dialogPayBoxVisible=true
},
    //点击订单明细按钮
    detailListFun(id){
         this.detailListData={
            detailListDialogFlag:true,
            id:id,
        }
    }
  },
  created() {
     this.initFun();
     this.GetListFun();
  },
  mounted() {
    
    // 获取当前门店的可用额度
    // getCurrentMemberStoreAvailableQuota().then(res=>{
    //     console.log('当前门店的可用额度',res.data.data)
    // })
    // // 获取结算单日期 
    //  getSettlementOrderDate().then(res=>{
    //   console.log('结算日期反回;',res.data.data[0])
    // })
  },
  components: {
    TitleHeader,
    Nav,
    ListDiolag,
    VueQr,
  },
  computed: {},
};
</script>
<style lang="less">
.redColor{
  color: #E00B0B;
}
.breadcrumb {
  padding-bottom: 20px;
  padding-top: 20px;
  color: #666666;
  font-size: 14px;
  line-height: 18px;
}
.CreditBox {
  padding:10px;
  background: #fff;
  position: relative;
  .Mymsg{
    position:absolute;
   left: 100px;
    color: #e00b0b;
  }
  .nav {
    li {
      float: left;
      padding: 10px;
      color: #666666;
      font-size: 14px;
    }
    .active {
      color: #ff3333;
    }
  }
  .el-date-editor {
    .el-input__prefix {
      right: -20px;
    }
  }
  .el-input__inner {
    border: none;
    background: #f6f6f6;
  }
  .el-table .cell {
    font-size: 12px;
    color: #333;
    padding:0;
    .repayBtn{
      margin-left:6px ;
    }
    .noRepayBtn{
      margin-left: 30px;
    }
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ff3333;
    color: #fff;
  }
  /* 上一页，下一页样式 */
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    background: #fff;
    color: #666;
    padding: 4px;
    box-sizing: border-box;
  }

  /* 每个页码的样式 */
  .el-pagination.is-background .el-pager li {
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #ff3333;
    font-size: 14px;
  }
}
</style>
<style lang="less" scoped>
@import "~style/index.less";
#QRCodeBox{
/deep/.el-dialog__body {
    padding: 0px 25px 10px !important;
    img {
      display: block !important;
      margin: 0 auto;
    }
  }
}
#TitMsgBox  /deep/.el-dialog__body{
  padding: 0px 25px 10px !important;
}
.BigTitle {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #2e2e32;
  margin-bottom:7px ;
}
.MidTitle {
    text-indent: 26px;
  font-size: 13px;
  line-height: 20px;
  color: #2e2e32;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  b{
    font-size: 14px;
    font-weight: bold;
  }
}

.content {
    padding-left: 12px;
    .charge {
      padding-top: 0px;
    }
    .charge span:first-child {
      padding-left:10px;
      width: 100px;
    display: inline-block;
    }
    .num {
      font-weight: 600;
      color: #ff3333;
      padding-right: 30px;
    }
    /deep/.el-form-item__label{
      text-align: left;
    }
    .el-form {
      width: 100%;
    }
    .el-input {
      width: 110px;
    }
    .wraper {
      display: flex;
      align-items: center;
      .el-form-item {
        padding-top: 20px;
        .imgWraper {
          width: 200px;
          height: 50px;
          border: 1px solid #dddddd;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          margin-left: 20px;
          padding: 0 50px;
          img {
            display: inline-block;
          }
          .el-icon-success {
            color: #cccccc;
            font-size: 28px;
          }
          &.active {
            border: 1px solid #ff3333;
            .el-icon-success {
              color: #ff3333;
            }
          }
        }
        .imgWraper:first-child {
          margin-left: 0px;
        }
      }
    }
    /deep/.el-button--primary {
      background-color: #ff3333;
      border-color: #ff3333;
      width: 70px;
      height: 40px;
      color: #fff;
      margin-top: 10px;
      padding-top: 10px;
    }
    /deep/.el-button + .el-button {
      margin-left: 30px;
    }
  }
.clear{
  clear: both;
}
.el-pagination {
  padding-top: 20px;
  text-align: right;
}
.CreditBox {
  .progressBar {
    height: 20px;
    margin-left: -10px;
    width: 1200px;
    background: #f6f6f6;
  }
  .fromWraper {
    padding: 20px 0;
    position: relative;
    span{
          position: absolute;
          right: 20px;
          top: 30px;
          line-height: 20px;
          font-size: 16px;
          strong{
              color: #ff3333;
              font-weight: bold;
          }
    }
  }

  .ulBox {
    background: #f6f6f6;
    height: 38px;
    line-height: 30px;
   
    li {
      float: left;
      text-align: center;
      color: #666666;
      width: 114px;
      line-height: 38px;
      font-size: 12px;
    }
    li.uldiv{
      width: 100px;
    }
    li:first-child {
      width: 56px;
    }
    li:nth-child(2) {
      width: 150px;
    }
  }
 .ulBoxs {
    background: #f6f6f6;
    height: 38px;
    line-height: 30px;
    li {
      float: left;
      text-align: center;
      color: #666666;
      width: 250px;
      line-height: 38px;
      font-size: 12px;
    }
    li:first-child {
      width: 180px;
    }
    // li:last-child {
    //   width: calc(100% - 760px);
    // }
  }
  .numWraper {
    padding: 36px;
    display: flex;
    align-items: center;
    position: relative;
    .CreditInstructions{
      position:absolute;
      right: 10px;
      top: 36px;
      color: #6baaff;
      font-size: 14px;
      &:hover{
        color:#0066CC;
      }
    }
    .balaceTit {
      font-size: 16px;
      color: #333;
    }
    // .btn {
    //   width:100%;
    //   height: 36px;
    //   text-align: left;
    //   line-height: 36px;
    //   font-size: 14px;
    //   font-weight: bold;
    //   padding: 0;
    //   color: #597EF7;
    // }
    .balaceNum {
      font-weight: bold;
      line-height: 65px;
      font-size: 30px;
    }
    ul li {
      float: left;
      padding-left: 20px;
    }
    .active {
      color: red;
    }
    .amountInfo {
      span {
        color: red;
        font-weight: 600;
        padding: 4px;
        font-size: 16px;
      }
      .rechargeInfo {
        padding-left: 20px;
      }
    }

    ul li {
      float: left;
      padding-left: 20px;
    }
    .active {
      color: red;
    }
    .amountInfo {
      span {
        color: red;
        font-weight: 600;
        padding: 4px;
        font-size: 16px;
      }
      .rechargeInfo {
        padding-left: 20px;
      }
    }
  }
}
</style>
